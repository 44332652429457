/* NewsPage.module.css */
.contentPlaceholder {
    text-align: center;
    padding: 50px;
    color: #ED6A29;
    background-color: #FDF3DD;
    border-radius: 10px;
    margin: 30px;
    border: 1px solid #CAAACD;
   /*  box-shadow: 0px 4px 8px rgba(0,0,0,0.0); */
    font-family: 'Arial', sans-serif;
  }
  
  .contentPlaceholder h2 {
    margin-bottom: 20px;
  }
  
  .contentPlaceholder p {
    margin-bottom: 10px;
    font-size: 1.3rem;
    color: #765778;
  }
  