.workContainer {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f4ece9;
    color: #ed6a29;
    border-radius: 10px;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    color: #765778;
  }
  
  .description {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
    color: #765778;
  }

  .workContainer h1 p {
    color: #765778;
  }
  

  .appLink {
    text-align: center;
    margin-top: 20px;
  }
  
  .appLink a {
    color: #ed6a29; /* Your theme color */
    text-decoration: none;
  }
  
  .appLink a:hover {
    text-decoration: underline;
  }

  .title h1 {
    color: #765778 !important;
  }

  .description p {
    color: #765778 !important;
  }

.linkDescription {
  color: #765778;
  text-align: center;
  font-size: 1.3rem;
}