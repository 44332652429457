/* ContactForm.module.css */

.formContainer {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
    padding: 2rem;
    background-color: #F4ECE9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .formField {
    margin-bottom: 1rem;
  }
  
  .formField label {
    display: block;
    margin-bottom: 0.5rem;
    color: #ED6A29;
    font-weight: bold;
  }
  
  .formField input[type="text"],
  .formField input[type="email"],
  .formField textarea {
    width: 90%;
    padding: 1rem;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .formField textarea {
    resize: vertical;
  }
  
  .formField input[type="submit"] {
    background-color: #ED6A29;
    color: #fff;
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }
  
  .formField input[type="submit"]:hover {
    background-color: #ca6a63;
  }
  
  /* Add additional media queries as needed for responsiveness */
  @media (max-width: 768px) {
    .formContainer {
      padding: 1rem;
    }
  }
  
  .thankYouMessage {
    text-align: center; /* Center the text horizontally */
    border: 2px solid #ED6A29; /* Example color for the border */
    padding: 20px;
    margin-top: 30px; /* Adds some space above the message */
    border-radius: 10px; /* Optional: if you want rounded corners */
    display: inline-block; /* To wrap the border around the content */
    color: #ED6A29; /* Example color for the text */
    background-color: #FDF3DD; /* Sets a background color for the message */
    margin-left: 20%;
    margin-right: 20%;
  }